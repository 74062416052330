<template>
  <div class="box-body">
    <card-items :data_search="data_search"
                :filters_query="filter_cars"
                :relations="relations"
                component="car-item"
                item_classes="col-md-6 col-sm-12 col-xs-12 mb-4"
                api_resource="/cars">
    </card-items>
  </div>
</template>

<script>
import Vue from "vue";
import CardItems from './../../../../components/pagination/card-items';

Vue.component('car-item', (resolve) => {
  return require(['./../../../cars/item'], resolve);
});

export default {
  name: 'task-suitable-cars',

  components: {
    CardItems,
  },

  props: {
    taskId: {
      type: [String, Number],
      required: true,
    },
    taskDetails: {
      type: [Object],
      required: true,
    },
    data_search: {
      type: String,
      required: false
    }
  },

  data: () => ({
    relations: [
      'branch',
      'equipments',
      'specification.gearbox',
      'specification.fuel',
      'specification.color',
      'specification.transmission',
      'seo',
      'creator',
      'editor',
    ],

    filter_cars: {
      owner_id: null,
    },
  }),

  async created() {
    try {
      if (this.taskDetails.mark) {
        this.filter_cars.mark_id = this.taskDetails.mark.id;

        if (this.taskDetails.model) {
          this.filter_cars.model_id = this.taskDetails.model.id;
        }
      }
      if (this.taskDetails.gearbox) {
        this.filter_cars.gearbox_id = this.taskDetails.gearbox.id;
      }
      if (this.taskDetails.year_from) {
        this.filter_cars.year_min = this.taskDetails.year_from;
      }
      if (this.taskDetails.year_to) {
        this.filter_cars.year_max = this.taskDetails.year_to;
      }
      if (this.taskDetails.price_from) {
        this.filter_cars.price_min = this.taskDetails.price_from;
      }
      if (this.taskDetails.price_to) {
        this.filter_cars.price_max = this.taskDetails.price_to;
      }
      if (this.taskDetails.race_from) {
        this.filter_cars.race_min = this.taskDetails.race_from;
      }
      if (this.taskDetails.race_to) {
        this.filter_cars.race_max = this.taskDetails.race_to;
      }
      if (this.taskDetails.engine) {
        this.filter_cars.engine_min = this.taskDetails.engine;
      }

      this.updateFilters();
    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {},

  methods: {
    updateFilters() {
      this.EventBus.$emit('card-filter-changed');
    },
  },
}
</script>

<style scoped>

</style>
